import React,{useState,useEffect,useRef, useContext} from 'react'
import {GiCancel} from "react-icons/gi"
import taskLogo from "../assets/TaskLogo.svg"
import AuthContext from '../contexts/AuthContext';
import axios from 'axios';
import Loader from './Loader';






function NewProjectPopup({projects,setNewProject,setProjects}) {
  const {authTokens,baseUrl}=useContext(AuthContext)
  const [loading,setLoading]=useState(false)
  const [title,setTitle]=useState("")
  const [error,setError]=useState("")
  const popupRef = useRef(null);
  const addProject= async ()=>{
    if(title.length!==0){
      setLoading(true)
      try{
        let res = await axios.post(`${baseUrl}teams/boards/`,{title:title},{
          headers: {
            authorization: `Bearer ${authTokens.access}`,
          },
        })
        console.log(res)
        setProjects([...projects,res.data])
  
        setLoading(false)
        closePopup()
      }
      catch(e){
        setLoading(false)
      }

    }
    else {
      setError('enter a project name please')
    }
  
 }

  useEffect(() => {
    
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closePopup = () => {
   setNewProject(false)
  };
  



  return (
    <div ref={popupRef} className=' animate-slideup dark:bg-[#FDF6F4] bg-[#1C1817]  sm:w-[50%] w-[90%] flex justify-center items-start flex-col rounded-[12px] z-[10] absolute py-4 ' >
        <div onClick={()=>{setNewProject(false)}} className=' w-full flex justify-end items-center px-4' > <button
        className=' cursor-pointer text-[25px] dark:text-black dark:hover:drop-shadow-xl dark:hover:bg-slate-50 dark:hover:bg-opacity-50 text-white hover:bg-light-gray hover:bg-opacity-25  rounded-full p-2 '  > <GiCancel/> </button> </div>
        <div className='w-full flex flex-col justify-center items-center gap-8 dark:text-black text-white ' >
            <img src={taskLogo} alt="" />
            <p className=' text-white dark:text-black text-[30px] ' >ADD NEW PROJECT</p>
            <div className=' ss:w-[70%] w-full flex flex-col justify-center items-start ' >
                <label htmlFor="" className=' text-gray-300  ' >project title</label>
                <input onChange={(e)=>{setTitle(e.target.value);setError('')}} placeholder='Enter the project title' className=' outline-none w-full bg-transparent py-2 px-4 border-[1px] border-[#b1b1b1] rounded-[5px] ' type='text' />
                <div className=' h-2 ' >
                  {error.length!==0 && <p className=' text-red-500 text-[10px] '  >
                        {error}
                  </p> }

                </div>

            

            </div>
            <button onClick={addProject} className=' bg-primary rounded-[5px] flex justify-center items-center h-10 w-44 text-white font-semibold ' > {loading ? <Loader/> : "ADD PROJECT" } </button>

            
               
        

        </div>
    </div>
  )
}

export default NewProjectPopup