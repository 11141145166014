import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL
  const urlLogout=`${baseUrl}account/profile/logout/`
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("tokens")
      ? JSON.parse(localStorage.getItem("tokens"))
      : null
  );
  let [user, setUser] = useState(() =>
    localStorage.getItem("tokens")
      ? jwt_decode(localStorage.getItem("tokens"))
      : null
  );
  const urlRefresh = `${baseUrl}api/token/refresh/`;

  const history = useNavigate();

  // let loginUser = async (email, password) => {
  //   setLoginLoading(true);
  //   try{
  //     let res = await axios.post(urlLogin, { email: email, password: password });

  //   let data = res.data;
  //   // console.log(res.response.status);
  //   setLoginLoading(false);
  //   setAuthTokens(data);
  //   setUser(jwt_decode(data.access));
  //   localStorage.setItem("authTokens", JSON.stringify(data));
  //   history("/");

  //   }
  //   catch(e){
  //     setLoginLoading(false);
  //     if(e.response.status===401){
  //       setErrorCred(true)
  //     }

  //   }
  // };
  let logoutUser = async () => {
    try{
      let res = await axios.post(urlLogout,{},{
        headers: {
          authorization: `Bearer ${authTokens.access}`,
        },
      })
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("tokens");
      history("/login")

    }
    catch(e){
      
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("tokens");
      history("/login")
    }

  };

  let updateToken = async () => {
    try{
    let res = await axios.post(urlRefresh, { refresh: authTokens?.refresh });
    let data = res.data;
    data = { ...data, refresh: authTokens.refresh };
    setAuthTokens(data);
    setUser(jwt_decode(data.access));
    localStorage.setItem("tokens", JSON.stringify(data));
    
    }
    catch(e){
      logoutUser()
    }
  };

  let contextData = {
    user: user,
    authTokens: authTokens,
    logoutUser: logoutUser,
    setUser: setUser,
    baseUrl:baseUrl,
    setAuthTokens:setAuthTokens
  };

  // useEffect(()=>{
  //   updateToken()
  // },[])

  useEffect(() => {
    let hour = 1000 * 60 * 5 ;
    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, hour);
    return () => clearInterval(interval);
  }, [authTokens]);

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  );
};
