import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [currentMode, setCurrentMode] = useState(
    localStorage.getItem("themeMode") || "dark"
  );
  const [themeSettings, setThemeSettings] = useState(false);
  const [urls, setUrls] = useState("");
  const [pay, setPay] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [remove, setRemove] = useState(false);
  const [pop1, setPop1] = useState(false);
  const [pop2, setPop2] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
 

 
  const setMode = (mode) => {
    setCurrentMode(mode);
    localStorage.setItem("themeMode", mode);
  };
  const [screenSize, setScreenSize] = useState(undefined);

  return (
    <StateContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        screenSize,
        setScreenSize,
        currentMode,
        setThemeSettings,
        themeSettings,
        urls,
        setUrls,
        pay,
        setPay,
        setMode,
        mainLoading,
        setMainLoading,
        remove,
        setRemove,
        pop1,
        setPop1,
        pop2,
        setPop2,
        selectedOption,
        setSelectedOption,
        showAlert,
        setShowAlert,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
