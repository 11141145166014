import React from 'react'
import LoginComponent from "../components/LoginComponent"
import LoginPopup2 from "../components/LoginPopup2"
import { useStateContext } from '../contexts/ContextProvider'

function Login() {
  const {currentMode}=useStateContext()
  return (
    <div className='flex flex-row justify-center min-h-screen w-full' >
      { false && <div className=" fixed md:left-50 top-0 left-0 w-full min-h-screen  bg-black bg-opacity-90  z-[99999] flex justify-center items-center   " >
                <LoginPopup2/>
              </div>}     
 


      <div className='min-h-screen h-full sm:w-[50%] w-[100%] ' >
        <LoginComponent/>
        

      </div>
      <div className={`sm:flex hidden min-h-screen w-[50%] ${currentMode==="dark" ? "zeroun" : "zerounLight"}  justify-center items-center`} >
        <div className=' w-[50%]' >
        <p className=' dark:text-black text-white text-[20px] font-montserrat font-[400] text-center ' >combines all tools & lets you analyse the results using various awesome filters.
       Optimised, our computation engine gives you faster & accurate results which can be used for further analysis.</p>


        </div>
      

      </div>

    </div>
  )
  
}

export default Login