import React, { useContext, useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { BiSolidDashboard } from "react-icons/bi";
import ProgressLin from "../components/ProgressLin";
import { BiFilterAlt } from "react-icons/bi";
import {useNavigate, useParams } from "react-router-dom";
import {AiFillStar} from "react-icons/ai"
import axios from "axios";
import AuthContext from "../contexts/AuthContext";
import NewListPopup from "../components/NewListPopup";
import {Kanban} from "../components/Kanban";




const GroupeProject = () => {
 
  const {authTokens,baseUrl}=useContext(AuthContext)
  const { currentMode } = useStateContext();
  const {title,id}=useParams()
  const navigate = useNavigate();
  const [lists,setLists]=useState([])
  const [columns, setColumns] = useState([]);
  const [addList,setAddList]=useState(false)
  const [loading,setLoading]=useState(true)
  const [completed,setCompleted]=useState(0)
  const [all,setAll]=useState(0)

  const getStats=(arr)=>{
    let tmp=0;
    arr.map(item=>tmp+=item.tasks.length)
    setAll(tmp)
    let tmp2 = arr.filter((item)=>item.title==="Done")
        setCompleted(tmp2[0]?.tasks?.length) 

        setLoading(false)
      }

  
  const generateMap = (vr) =>{
    let obj={}
   vr.map((item,index)=>{
     obj={...obj,[item.id]:{
      title:item.title,
      items:item.tasks
     }}
    })
   return obj
  }

  const getLists= async ()=>{
    setLoading(true)
    try{
      let res = await axios.get(`${baseUrl}teams/boards/${id}/lists/`,{
        headers: {
          authorization: `Bearer ${authTokens.access}`,
        },
      })
      
     let tmp =  generateMap(res?.data?.results)
     getStats(res.data.results)
    //  setLists(tmp)
     setColumns(tmp)
     setLoading(false)
   

    }
    catch(e){
      setLoading(false)

    }

  }

  useEffect(()=>{
   getLists()
  },[])
  return (
    <div
      className={`w-full  ${
        currentMode === "dark" ? "zeroun2" : "zeroun2Light"
      } items-stretch flex-grow xl:px-32 md:px-16 px-8  py-10 `}
    >
      
      {addList && (
        <div className=" fixed left-0 top-0 w-[100%] h-screen bg-black bg-opacity-60 backdrop-blur-sm z-[9999] flex justify-center items-center  ">
          <NewListPopup setAddList={setAddList} columns={columns} setColumns={setColumns}  />
        </div>
      )}
      <div className=" w-full flex flex-row gap-4 justify-between items-center ">
        <div className="flex flex-row gap-4 justify-start flex-wrap items-center ">
          <button
            onClick={() => navigate("/")}
            className=" hover:cursor-pointer dark:text-black hover:bg-primary font-semibold flex justify-center items-center text-white bg-transparent border-[#C00000] border-[1px] rounded-[5px] py-2 px-8 "
          >
            <BiSolidDashboard />
            HOME
          </button>
          <button className=" text-white dark:text-black font-semibold bg-transparent border-[#C00000] border-[1px] rounded-[5px] py-2 px-8 ">
            {title}
          </button>
         <div className=" flex flex-row justify-center items-center gap-2 " >
         <div className=" sm:w-[300px] w-[200px] flex flex-col justify-start items-start">
            <p className=" flex flex-row justify-center items-center gap-1 text-white dark:text-black ">
              Task Meter
              <span className=" font-semibold text-blue-500 ">{completed || 0 }</span>
               / {all}
            </p>
            <div className=" w-full gap-2 flex justify-start items-center ">
              <ProgressLin />
              <p className=" text-yellow-400 flex justify-center items-center gap-1 "> <AiFillStar/> Good job!</p>
            </div>
          </div>
        

         </div>
        </div>

        <div className=" flex flex-row gap-4 justify-center flex-wrap items-center ">
          <div className=" dark:text-black text-white flex flex-row gap-2 justify-center items-center ">
            <BiFilterAlt className=" text-[20px] dark:text-black text-white" />
            Filter
          </div>
          <div className=" flex flex-row justify-center items-center ">
            <div
              onClick={() => navigate("/profile")}
              className=" hover:cursor-pointer text-[8px] text-white flex justify-center items-center h-6 w-6 rounded-full bg-green-600 "
            >
              AG
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-10 flex flex-row flex-wrap justify-start gap-10 items-start ">
       
        {!loading && <Kanban idProject={id} columns={columns} setColumns={setColumns} data={lists} setAddList={setAddList} />}

      
      </div>
    </div>
  );
};

export default GroupeProject;
