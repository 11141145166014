import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom/dist';
import { ContextProvider } from './contexts/ContextProvider';
import { AuthProvider } from './contexts/AuthContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Router>
  <AuthProvider>
    <ContextProvider>
    <DndProvider backend={HTML5Backend}>
    <App />
      </DndProvider>
      
    </ContextProvider>
  </AuthProvider>
</Router>
  
);

