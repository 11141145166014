import React,{useContext} from 'react'
import {  useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import logo from "../assets/Logoww.svg";
import taskLogo from "../assets/TaskLogo.svg";
import {FiLogOut} from "react-icons/fi"
import AuthContext from '../contexts/AuthContext';

function FixedBar({title,icon,showHistory,iconLight}) {
  const { logoutUser } = useContext(AuthContext);
  const navigate=useNavigate()
  return (
    <div className="flex justify-between w-full items-center sm:px-10 md:px-8 px-8 py-2">
    <div className=' ' >
      <img onClick={()=>navigate("/")} src={taskLogo} alt="" className=' hover:cursor-pointer h-16 w-40  object-contain' />
      </div>  
    <div className=" h-10 gap-4 flex justify-center items-center">
      <button className=' hover:bg-primary bg-transparent dark:text-black text-white font-semibold border-[#C00000] border-[1px] rounded-[6px] px-8 py-2 flex justify-center items-center gap-2 ' >
        <a className=' flex flex-row justify-center items-center gap-2 ' href="https://vulnvision.com" target='_blank' >
        <img src={logo} alt="" className=' w-6 h-6 object-contain ' />
        GO TO VULNVISION

        </a>

      </button>
      <button onClick={()=>navigate("/profile")} className=' hover:bg-primary bg-transparent dark:text-black text-white font-semibold border-[#C00000] border-[1px] rounded-[6px] px-8 py-2 flex justify-center items-center gap-2 ' >
        
       
        Profile

      </button>

 
      <div 
      onClick={()=>{
        logoutUser()
      }}
      className="relative dark:text-black py-2 px-2 rounded-full hover:cursor-pointer hover:bg-slate-50 hover:bg-opacity-20 text-[20px] text-white ">
        
        <FiLogOut />
          
      </div>
     
    </div>
  </div>
  )
}

export default FixedBar