import { useEffect, useState, useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useStateContext } from "./contexts/ContextProvider";
import PrivateRoute from "./utils/PrivateRoute";
import AuthContext from "./contexts/AuthContext";
import Dashboard from "./pages/Dashboard"
import HomeRoute from "./utils/HomeRoute";
import Login from "./pages/Login"
import FixedBar from "./components/FixedBar";
import GroupeProject from "./pages/GroupeProject";
import Profile from "./pages/Profile";

function App() {
  const { user } = useContext(AuthContext);
  const {
    activeMenu,
    setActiveMenu,
    screenSize,
    setScreenSize,
    currentMode,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 1140) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  return (
    <>
      <div className={` ${currentMode === "light" ? "dark" : ""}`}>
          <div
            className={` min-h-screen flex flex-col w-full`}
          >
              {user && <div className=" dark:bg-[#FDF6F4] bg-black z-10 w-full sticky top-0 left-0  " >
                <FixedBar />
              </div> }
             <Routes>
                <Route element={<PrivateRoute />}>
                  <Route exact path="/" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/project/:title/:id" element={<GroupeProject />} />
                  <Route path="/profile" element={<Profile />} />
                </Route>
                <Route element={<HomeRoute />}>
                  <Route path="/login" element={<Login />} />
                  {/* <Route path="/login/reset password" element={<ResetPass />} />
                  <Route path="/reset_password" element={<ResetPass2 />} /> */}
                </Route>
              </Routes>
            </div>
          </div>
    </>
  );
}

export default App;
