import React, { useState, useEffect, useRef, useContext } from "react";
import docker from "../assets/docker.png";
import error404 from "../assets/404.svg";
import Loader from "./Loader";
import logobg2 from "../assets/loader.gif";
import { AiOutlineClose } from "react-icons/ai";
import boukhatem from "../assets/boukhatem.svg";
import tmp from "../assets/imgSvg.svg";
import Select from "react-select";
import axios from "axios";
import AuthContext from "../contexts/AuthContext";
import avatar from "../assets/avatar.png"

const Comment = ({ item, comments, setComments }) => {
  const { baseUrl, authTokens } = useContext(AuthContext);
  const [modify, setModify] = useState(false);
  const [newComment, setNewComment] = useState(item.comment);
  const [loading, setLoading] = useState(false);

  const deleteComment = async () => {
    setLoading(true);
    try {
      let res = await axios.delete(`${baseUrl}teams/comments/${item.id}/`);
      console.log(res)
      let tmp = comments.filter((it) => item.id !== it.id)
      setComments(tmp);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  function calculerDate(date) {
    const currentDate = new Date();
    const targetDate = new Date(date);
    const timeDifference = targetDate - currentDate;
    const secondsDifference = timeDifference / 1000;
  
    if (secondsDifference < 60) {
      return "Just now";
    } else if (secondsDifference < 3600) {
      const minutes = Math.floor(secondsDifference / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (secondsDifference < 86400) {
      const hours = Math.floor(secondsDifference / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(secondsDifference / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  }

  const updateComment = async () => {
    try {
      let res = await axios.patch(
        `${baseUrl}teams/comments/${item.id}/`,
        { comment: newComment },
        {
          headers: {
            authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      key={item?.id}
      className=" w-full flex flex-col gap-1 justify-start items-start  "
    >
      <div className=" text-slate-400 text-[12px] w-full flex flex-row justify-start items-center gap-2 ">
        <img
          src={item?.created_by?.image ? item?.created_by?.image   : avatar }
          alt="person"
          className=" h-6 w-6 object-contain "
        />
        <p>{item?.created_by?.full_name}</p>
        <p>{calculerDate(item?.updated_at)}</p>
      </div>
      <div className=" text-white px-4 w-full flex flex-col justify-center items-start ">
        {item.image !== null && (
          <img
            src={item?.image}
            alt="capture"
            className=" h-10 w-10 object-contain "
          />
        )}
        {modify ? (
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Write the comment here"
            className=" outline-none w-full text-white flex-grow items-stretch flex-shrink bg-transparent py-2 px-4 border-[1px] border-[#fff] dark:border-[#000] rounded-md  "
          />
        ) : (
          <p className=" w-full flex flex-row gap-2 justify-start items-start ">
            {" "}
            <span className=" font-semibold ">-</span>
            {newComment}{" "}
          </p>
        )}
      </div>
      <div className=" w-full justify-start items-center flex flex-row gap-3 ">
        <p
          onClick={() => {
            setModify((prev) => !prev);
            if (modify) updateComment();
          }}
          className=" hover:cursor-pointer hover:underline hover:underline-offset-2 text-slate-400 "
        >
          {modify ? "Save" : "Edit"}
        </p>
        <p
          onClick={() => deleteComment()}
          className=" hover:cursor-pointer hover:underline hover:underline-offset-2 text-slate-400 "
        >
          {loading ? <Loader /> : "Delete"}
        </p>
      </div>
    </div>
  );
};

function TaskDetails({
  setShowTask,
  clickedId,
  setClickedId,
  taskInfo,
  setTaskInfo,
}) {
  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#1C1817",
    }),
    menu: (provided) => ({
      ...provided,

      maxHeight: 200,
      overflowY: "auto",
    }),
    control: (provided) => ({
      ...provided,
      border: "none",
      outline: "none",
      boxShadow: "none",
      borderRadius: "0",
      backgroundColor: "transparent",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4299e1" : "#fff",
      color: state.isSelected ? "#fff" : "#4a5568",
      ":hover": {
        backgroundColor: "#EDE0DD",
      },
    }),
  };
  const { baseUrl, authTokens } = useContext(AuthContext);
  const popupRef = useRef(null);
  const [comment, setComment] = useState("");
  const [tab, setTab] = useState("comments");
  const fileInputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [history, setHistory] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [memberInfos, setMemberInfos] = useState({});
  const [selectedAssign, setSelectedAssign] = useState({
    label: taskInfo?.assigned_to?.username,
    value: taskInfo?.assigned_to?.id,
  });
  const [members, setMembers] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedReporter, setSelectedReporter] = useState({
    label: taskInfo?.reporter_name,
    value: "azdazd",
  });
  const handleAssign = (selectedOption) => {
    setSelectedAssign(selectedOption);
  };
  const handlePriority = (selectedOption) => {
    setSelectedPriority(selectedOption);
  };
  const handleReport = (selectedOption) => {
    setSelectedReporter(selectedOption);
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  const updateTask = async (tp) => {
    try {
      let res = await axios.patch(
        `${baseUrl}teams/tasks/${taskInfo?.id}/update/`,
        { assigned_to: tp },
        {
          headers: {
            authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
    
    } catch (e) {
      console.log(e);
    }
  };

  const getComments = async () => {
    try {
      let res = await axios.get(`${baseUrl}teams/tasks/${clickedId}/comments/`);
      console.log(res);
      setComments(res?.data?.results);
    } catch (e) {}
  };

  const getHistory = async () => {
    try {
      let res = await axios.get(`${baseUrl}teams/tasks/${clickedId}/history/`);
    } catch (e) {}
  };

  const getMembers = async () => {
    try {
      let res = await axios.get(`${baseUrl}teams/members/`, {
        headers: {
          authorization: `Bearer ${authTokens.access}`,
        },
      });

      let tmp = res.data.results.map((item) => {
        return {
          label: item?.username,
          value: item?.id,
        };
      });
      setMembers(tmp);
    } catch (e) {}
  };

  const getMember = async () => {
    try {
      let res = await axios.get(
        `${baseUrl}teams/members/${selectedAssign?.value}/`,
        {
          headers: {
            authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      setMemberInfos(res?.data);
      return res?.data
    } catch (e) {}
  };

  useEffect(() => {
    getMember().then(res=>updateTask(res))
  }, [selectedAssign]);

  useEffect(() => {
    getComments();
    getHistory();
    getMembers();
  }, []);


  


  const addComment = async () => {
    setLoading(true)
    let formData = new FormData();
    formData.append("comment", comment);
    formData.append("task", clickedId);
    formData.append("image", selectedFile);
    let data =
      selectedFile === null
        ? {
            comment: comment,
            task: clickedId,
            image: null,
          }
        : formData;

    try {
      let res = await axios.post(`${baseUrl}teams/comments/create/`, data, {
        headers: {
          authorization: `Bearer ${authTokens.access}`,
        },
      });
      console.log(res);
      
      setComments([...comments, res.data]);

      setComment("");
      setSelectedFile(null);
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closePopup = () => {
    setShowTask(false);
    setSelectedFile(null);
    setComment("");
  };

  return (
    <div
      ref={popupRef}
      className=" animate-slideup dark:bg-white bg-[#1C1817] rounded-[24px] overflow-scroll py-6 px-6  w-[80%] z-[] absolute "
    >
      <div className=" w-full flex justify-between items-center ">
        <p className=" text-slate-300 ">Project:vuln</p>
        <AiOutlineClose
          onClick={closePopup}
          className=" hover:cursor-pointer text-white text-[20px] "
        />
      </div>
      <div className=" mt-4 w-full flex-col gap-6 flex justify-center items-start ">
        <p className=" dark:text-black text-[20px] text-white font-semibold ">
          Task Details Popup
        </p>
      </div>
      <div className=" mt-4 flex w-full sm:flex-row flex-col sm:gap-6 gap-2 justify-between ">
        <div className=" flex-1 flex flex-col justify-between gap-6 items-center  ">
          <div className=" w-full flex justify-start items-center ">
            <p className="dark:text-black font-semibold text-white">
              Description
            </p>
          </div>
          <div className=" py-2 px-6 max-h-[200px] overflow-y-scroll  border-[1px] w-full border-[#fff] rounded-md  ">
            <p className="dark:text-black font-semibold text-white">{taskInfo?.title}</p>
            <p className=" dark:text-black text-white ">
              {taskInfo?.description}
            </p>
          </div>
          <div className=" w-full flex flex-col gap-3 justify-start items-start ">
            <p className="dark:text-black text-white font-semibold">
              Activities
            </p>
            <div className=" w-full flex flex-row gap-3 justify-start items-center ">
              <p className="dark:text-black text-slate-300">Show:</p>
              <button
                onClick={() => setTab("comments")}
                style={{
                  background: tab === "comments" ? "#775651" : "transparent",
                }}
                className="dark:text-black py-1 px-4 text-slate-300"
              >
                comments
              </button>
              {/* <button
                onClick={() => setTab("history")}
                style={{
                  background: tab === "history" ? "#775651" : "transparent",
                }}
                className="dark:text-black py-1 px-4 text-slate-300"
              >
                history
              </button> */}
            </div>
          </div>

          <div className=" relative w-full flex justify-start items-center gap-2 ">
            <img
              src={taskInfo?.reporter_image ? taskInfo?.reporter_image  : avatar  }
              alt=""
              className=" h-10 w-10 object-contain "
            />
            <input
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Write the comment here"
              className=" outline-none text-white flex-grow items-stretch flex-shrink bg-transparent py-2 px-4 border-[1px] border-[#fff] dark:border-[#000] rounded-md  "
            />
            <div className=" absolute z-20 top-3 right-4  ">
              <img
                onClick={handleFileUpload}
                src={tmp}
                alt=""
                className=" hover:cursor-pointer "
              />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden z-10"
              />
            </div>
          </div>
          {(comment.length !== 0 || selectedFile !== null) && (
            <div className="flex flex-row justify-start gap-6 w-full items-center  ">
              <button className=" h-8 px-5 border-[#C00000] rounded-md border-[1px] text-white font-semibold ">
                cancel
              </button>
              <button
                onClick={addComment}
                className=" h-8 w-20 flex justify-center items-center bg-[#C00000] rounded-md text-white font-semibold "
              >
                {loading ? <Loader/>   : "Save"   }
              </button>
            </div>
          )} 
          <div className=" max-h-[200px] overflow-y-scroll px-6 w-full flex flex-col gap-2 justify-start items-start ">
            {comments.map((item, index) => (
              <Comment
                comments={comments}
                setComments={setComments}
                item={item}
                key={item.id}
              />
            ))}
          </div>
        </div>
        <div className=" flex-1 py-2 px-6 flex flex-col justify-around items-start border-[1px] border-[#fff] rounded-md  ">
          <h1 className=" text-white text-[25px] dark:text-black font-semibold ">
            Details
          </h1>
         
            <div className=" w-full flex flex-row justify-between items-center ">
              <p className=" flex-1 text-white dark:text-black ">Assigne:</p>
              <div className=" flex-1 flex justify-center items-center gap-2 ">
                {memberInfos?.image?.length !== 0 ? (
                  <img
                    src={memberInfos?.image}
                    alt=""
                    className=" h-10 w-10 object-contain "
                  />
                ) : (
                  <div className=" h-6 rounded-full text-white flex justify-center items-center text-[10px] w-6 bg-green-500 ">
                    AG
                  </div>
                )}
                <Select
                  className=" hover:cursor-pointer w-44 "
                  placeholder="assign"
                  styles={customStyles}
                  options={members}
                  value={selectedAssign}
                  onChange={handleAssign}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className=" w-full flex flex-row justify-between items-center ">
              <p className=" flex-1 text-white dark:text-black ">Status:</p>
              <button className=" flex-1 px-4 py-2 text-white dark:text-black border-[2px] border-[#C00000]  ">
                {`${taskInfo?.priority} priority`}
              </button>
            </div>
            <div className=" w-full flex flex-row justify-between items-center ">
              <p className=" flex-1 text-white dark:text-black ">Reporter:</p>
              <div className=" flex-1 flex justify-center items-center gap-2 ">
                <img
                  src={taskInfo?.reporter_image ? taskInfo?.reporter_image   : avatar }
                  alt=""
                  className=" h-10 w-10 object-contain "
                />
                <p className=" text-slate-400 w-44 ">
                  {taskInfo?.reporter_name}
                </p>
                {/* <Select
                  className=""
                  placeholder="report"
                  styles={customStyles}
                  options={[]}
                  value={selectedReporter}
                  onChange={handleReport}
                /> */}
              </div>
            </div>
            {/* <div className=" w-full flex flex-col items-start justify-center ">
              <p className=" text-slate-500 ">Created 15-10-2023</p>
              <p className=" text-slate-500 ">Updated 13 hours ago</p>
            </div> */}
        </div>
      </div>
    </div>
  );
}

export default TaskDetails;
