import React,{useContext,useState} from 'react'
import {BsArrowLeft} from "react-icons/bs"
import Loader from "../components/Loader"
import { Link,useNavigate } from 'react-router-dom'
import logo from "../assets/logo2.png"
import logoBlack from "../assets/Logo3.png"
import AuthContext from '../contexts/AuthContext'
import { emailRegex,passwordRegex } from '../utils/Rejex'
import { useStateContext } from '../contexts/ContextProvider'
import axios from "axios";
import jwt_decode from "jwt-decode";

export function InpLabel({msgfunc,handleLogin,setErrorCred,text,placeholder,type,statefunc,errorfunc,err}){
  return (
    <div className='mt-4 flex justify-center items-center gap-2 flex-col' >
      <div className=' text-[12px] w-full flex justify-start items-center dark:text-black text-[#EDE0DD] font-[200] ' >{text}</div>
      <div className=' w-full' >
        <input onKeyUp={(event)=>{
          if (event.key === 'Enter') {
            handleLogin();
            
          }
        } } style={ err   ? {borderColor:"#C00000"}    : {borderColor:"#A08C89"} } onChange={(e)=>{ msgfunc(""); setErrorCred(false);statefunc(e.target.value);errorfunc(false)}} className='dark:text-black px-4 outline-none w-full rounded-[4px] border-[1px] bg-transparent py-2 placeholder:text-[#EDE0DD] placeholder:font-[100] placeholder:text-[12px] text-white' type={type} placeholder={placeholder} />
      </div>
    </div>
  )
}
function LoginComponent() {
  const {baseUrl,setAuthTokens,setUser} = useContext(AuthContext)
  const {currentMode} = useStateContext()
  const [emailErr,setEmailErr]=useState(false)
  const [pwdErr,setPwdErr]=useState(false)
  const [emailMsg,setEmailMsg]=useState("")
  const [pwdMsg,setPwdMsg]=useState("")
  const [email,setEmail]=useState("")
  const [loginLoading, setLoginLoading] = useState(false);
  const [errorCred,setErrorCred]=useState(false)
  const [pwd,setPwd]=useState("")
  const urlLogin = `${baseUrl}api/token/`;

  const history = useNavigate();
  const navigate=useNavigate();

  let loginUser = async (email, password) => {
    setLoginLoading(true);
    try{
      let res = await axios.post(urlLogin, { email: email, password: password });

    let data = res.data;
    console.log(jwt_decode(data.access))
    setLoginLoading(false);
    setAuthTokens(data);
    setUser(jwt_decode(data.access));
    localStorage.setItem("tokens", JSON.stringify(data));
    history("/dashboard");

    }
    catch(e){
      setLoginLoading(false);
      if(e?.response?.status===401){
        setErrorCred(true)
      }

    }
  };

  const handleLogin=()=>{
   

    if(emailRegex.test(email)){
      loginUser(email,pwd)
      
    }
    else{
      if(email.length===0){
        setEmailErr(true)
        setEmailMsg("this field is required")
      }
  
       else if(!emailRegex.test(email)){
        setEmailMsg("enter a correct email adress")
       setEmailErr(true)
  
       } 
  
        if(pwd.length===0){
          setPwdMsg("this field is required")
          setPwdErr(true)
         
        }
        else  if(!passwordRegex.test(pwd)) {setPwdMsg("your password is too week");setPwdErr(true) }
     

    }
 


  }
  return (
    <div className=' dark:bg-white bg-[#1C1817] min-h-screen flex flex-col items-center ' >
       {/* <div className=' w-full mt-10 px-4' >
         <span className='text-[20px] dark:text-black font-[900] text-white hover:cursor-pointer' > <Link to={"/home"} ><BsArrowLeft/></Link> </span>
       </div> */}
       <div className=' mt-20 w-full flex justify-center items-center' >
        <img src={ currentMode==="dark" ? logo : logoBlack  } alt="logo" />

       </div>
       <div className='sm:w-[60%] md:w-[50%] w-[70%]  mt-20 ' >
        <div className='my-4' > <span className=' dark:text-black text-white text-[37px] font-montserrat font-[100]
        ' >Sign in</span> </div>
        <div className=' flex justify-center flex-col ' >
          <div className=' flex flex-col' >
          <InpLabel 
          handleLogin={handleLogin}
          setErrorCred={setErrorCred}
          err={emailErr} 
          msgfunc={setEmailMsg}
          errorfunc={setEmailErr} 
          statefunc={setEmail} 
          text="Email address" placeholder="Enter your email " type="email" />

          <div className=' h-2' >
        {emailMsg.length!==0 && <p className=' text-[10px] text-primary' >{emailMsg}</p>  } 
          </div> 
          </div>

          <div className=' flex flex-col' >
          <InpLabel handleLogin={handleLogin}  setErrorCred={setErrorCred} err={pwdErr} errorfunc={setPwdErr} msgfunc={setPwdMsg} statefunc={setPwd} text="Password" placeholder="Enter the Password  " type="password" />


          <div className=' h-2' >
        {pwdMsg.length!==0 && <p className=' text-[10px] text-primary' >{pwdMsg}</p>  } 
          </div> 

          </div>
         
          
          <div className={` dark:text-black flex ${errorCred ? "justify-between"    : "justify-end" }  items-start text-[12px] text-white mt-1 `}  >
            {errorCred && <p className=' text-[12px] text-red-500' >check your informations again!</p> }
            <p onClick={()=>navigate('/login/reset password')} className=' hover:underline hover:underline-offset-1 hover:cursor-pointer' >Forgot password?</p>
            </div>
          
          
          
        </div>

       </div>
       <div className=' h-10 w-full flex justify-center items-center' >
        <button 
        onKeyUp={(event)=>{
          if (event.key === 'Enter') {
            handleLogin();
            
          }
        } }
        onClick={handleLogin}
        autoFocus
        tabIndex="0"
        className='h-full focus:outline-none flex justify-center items-center text-white sm:w-[50%] w-[70%] bg-main-bg mt-8 rounded-[4px] font-[500]' >
          {loginLoading ?  <Loader/>  : "Sign in" }
          </button>

       </div>


       {/* <div className=' w-full mt-8 flex justify-center items-center' >
          <p className=' sm:w-[50%] w-[70%] sm:text-center dark:text-black text-[#EDE0DD]' >Don't have an account? <span className=' dark:text-black text-white font-bold' > <Link to={"/register"} >Sign up now</Link> </span> </p>       
       </div> */}
    </div>
  )
}

export default LoginComponent