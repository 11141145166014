import React,{useContext, useEffect, useState} from 'react'
import { useStateContext } from '../contexts/ContextProvider'
import ProgressLin from '../components/ProgressLin'
import logo from "../assets/logo2.png"
import def from "../assets/def.svg"
import NewProjectPopup from '../components/NewProjectPopup'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../contexts/AuthContext'
import axios from 'axios'
import { timeFormat } from "d3-time-format";
import {BsFillTrashFill} from "react-icons/bs"


const Card=({item,projects,setProjects})=>{
  const {baseUrl,authTokens}=useContext(AuthContext)
  const navigate = useNavigate()
  const date = new Date(item?.created_at);
  const formatSpecifier = "%Y-%m-%d %H:%M";
  const formatDate = timeFormat(formatSpecifier);
  let res = formatDate(date);
  const [completed,setCompleted]=useState(0)
  const [all,setAll]=useState(0)
  const [loading,setLoading]=useState(true)

  const getStats=()=>{
    let arr = item.lists
    let tmp=0;
    arr.map(item=>tmp+=item.tasks.length)
    setAll(tmp)
    let tmp2 = arr.filter((item)=>item.title==="Done")
        setCompleted(tmp2[0]?.tasks?.length ||0 ) 

        setLoading(false)
      }

   const handleDelete= async (e)=>{
    e.stopPropagation()
    try{
       let res = await axios.delete(`${baseUrl}teams/boards/${item?.id}/delete/`,{
        headers: {
          authorization: `Bearer ${authTokens.access}`,
        },
      })
      console.log(res)
       let tmp = projects.filter(it=>it.id!==item.id)
       setProjects(tmp)


    }
    catch(e){

    }

   }   
 

 useEffect(()=>{
  getStats()

  
 },[])

  return <div onClick={()=>navigate(`/project/${item?.title}/${item?.id}`)}  className='dark:bg-[#FDF6F4] hover:cursor-pointer hover:scale-105 transition duration-300 relative bg-[#1C1817] rounded-[6px] w-[300px] p-6  flex flex-col gap-2 ' >
    <div className=' w-full flex justify-between items-center ' >
    <p className=' text-gray-400 font-semibold ' >{res}</p>
    <BsFillTrashFill onClick={(e)=>handleDelete(e)} className="text-[#C00000] hover:cursor-pointer hover:scale-110 transition duration-300  text-[20px] " />

    </div>
    <h1 className=' text-white dark:text-black font-semibold ' >{item?.title}</h1>
    <p className=' text-white dark:text-black ' >{`${completed}/${all}`}</p>
    <div className=' w-full flex justify-start' >
    {!loading && <ProgressLin value={ all!==0 ? parseInt((completed/all*100).toFixed(0)) : 0 } /> }
    </div>
    <div className=' w-full flex flex-row justify-between items-center ' >
      <p className=' dark:text-black text-white  ' >Progress</p>
      <p className=' dark:text-black text-white  ' >{all!==0 ? `${(completed/all*100).toFixed(0)} %` : '0%' }</p>

    </div>
    <div className=' h-[2px] bg-gray-400 w-full ' />
    

  </div>
}


const Dashboard = () => {
  const {currentMode}=useStateContext()
  const [newProject,setNewProject]=useState(false)
  const {baseUrl,authTokens}=useContext(AuthContext)
  const [projects,setProjects]=useState([])
  const navigate = useNavigate()


 

  const getProjects=async()=>{
     try{
      let res = await axios.get(`${baseUrl}teams/boards/`,{
        headers: {
          authorization: `Bearer ${authTokens.access}`,
        },
      })
      setProjects(res?.data?.results)
     }
     catch(e){

     }
  }
 
 useEffect(()=>{
   getProjects()
 },[])

  return (
    <div className={`w-full  ${
        currentMode === "dark" ? "zeroun2" : "zeroun2Light"
      } items-stretch flex-grow xl:px-32 md:px-16 px-8 flex md:flex-row flex-col gap-4  sm:py-10 py-2 `} >
         {newProject && (
                <div className=" fixed left-0 top-0 w-[100%] h-screen bg-black bg-opacity-60 backdrop-blur-sm z-[9999] flex justify-center items-center  ">
                  <NewProjectPopup projects={projects} setProjects={setProjects} setNewProject={setNewProject} />
                </div>
              )}
         <div className=' py-12 relative  flex-1 flex flex-col md:w-[70%] w-full  ' >
         
            <div className=' mt-6 w-full flex flex-col gap-8 ' >
              <div className=' w-full flex flex-row justify-start items-center ' >
                <h1 className=' text-[20px] text-white dark:text-black font-semibold  ' >My projects:</h1>
               
              </div>
              <div className=' w-full flex justify-start gap-7 items-center flex-wrap ' >
                {projects?.map((item,index)=>
                (<Card projects={projects} setProjects={setProjects} item={item} key={item.id} />
                ))}
                </div>
                <div className=' absolute bottom-0 mt-4 w-full flex justify-start items-center ' >
                <button onClick={()=>setNewProject(true)} className=' bg-primary rounded-[5px] py-2 px-8 text-white font-semibold ' >CREATE NEW PROJECT</button>

                </div>

            </div>

        </div>
        <div className=' md:w-[25%] w-full ' >
          <div className='dark:bg-[#FDF6F4] relative h-full bg-[#1C1817] flex flex-col md:gap-0 gap-8 justify-around py-4 rounded-[5px] px-4 items-center' >
            <img src={logo} alt="" />
            <h1 className=' text-white dark:text-black font-semibold ' >The Most Enhancement Attack Service Management Tool.</h1>
            <p className='text-white dark:text-black'  >combines all tools & lets you analyse the results using various awesome filters. Optimised, our computation engine gives you faster & accurate results which can be used for further analysis.</p>
            <img src={def} alt="" />
            <button className=' bg-primary hover:scale-105 transition duration-300 rounded-[5px] py-2 px-8 text-white font-semibold ' > <a target='_blank' href="https://vulnvision.com">GO TO VULNVISION</a></button>



          </div>

        </div>
       

    </div>
  )
}

export default Dashboard