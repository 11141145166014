import React, { useContext, useState, useEffect, useRef } from "react";
import avatar from "../assets/avatar.png";
import { BiLogOutCircle } from "react-icons/bi";
import { AiFillCamera } from "react-icons/ai";
import  Loader  from "../components/Loader";
import AuthContext from "../contexts/AuthContext";
import axios from "axios";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import {motion} from "framer-motion"

export const InputLabel = ({ attr, item, width, label, value, func }) => (
  <div className={` mt-3 w-[${width}] gap-1 flex flex-col`}>
    <label className=" text-white font-semibold" htmlFor={label}>
      {label}
    </label>
    <input
      value={value}
      onChange={(e) => {
        func({ ...item, attr: e.target.value });
      }}
      className=" text-white outline-none rounded-[4px] py-2 px-2 bg-[#1C1817] border-[1px] border-[#A08C89]"
      type="text"
    />
  </div>
);

function Profile() {
  const { baseUrl, authTokens } = useContext(AuthContext);
  const urlGetInfo = `${baseUrl}account/profile/`;
  const urlUpdate = `${baseUrl}account/profile/update/`;
  const navigate=useNavigate()
  // const urlReset=`${baseUrl}account/reset_password/send_email/`
  const urlConfirm=`${baseUrl}account/profile/emails/reactive/`
  const { currentMode } = useStateContext();
  const [active,setActive]=useState(true)
  const [loading, setLoading] = useState(false);
  const [emailLoading,setEmailLoading]=useState(false)
  const [settings,setSettings]=useState(false)
  const fileInputRef = useRef(null);
  const [checkMsg,setCheckMsg]=useState("")
  const [profilInfo, setProfileInfo] = useState({
    phone: "",
    email: "",
    image: "",
    full_name: "",
  });
  const [error, setError] = useState({
    errorName: false,
    phoneError: false,
    imageError: false,
  });
  const [nameEmail, setNameEmail] = useState({
    name: "",
    email: "",
  });
  const [bool, setBool] = useState(false);
  const [pic, setPic] = useState(null);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    let file = event.target.files[0];
    console.log(file);
    if (file.type.startsWith("image/")) {
      setPic(event.target.files[0]);
    } else {
      setPic(null);
    }
  };

  const confirmEmail= async () => {
    setEmailLoading(true)
    try{
      let res = await axios.post(urlConfirm,{},
        {
          headers: {
            authorization: `Bearer ${authTokens.access}`,
          },
        }
        )

        console.log(res)
        setCheckMsg("check your mail please!")
        setEmailLoading(false)

    }
    catch(e){
      console.log(e.response)
      setEmailLoading(false)

    }


  }

  const getProfileInfo = async () => {
    try {
      let res = await axios.get(urlGetInfo, {
        headers: {
          authorization: `Bearer ${authTokens.access}`,
        },
      });
      // setProfileInfo(res.data);  
      console.log(res.data)
      setProfileInfo({
        phone: res?.data?.phone ||"" ,
        email: res?.data?.email||"",
        image: res?.data?.image||"",
        full_name: res?.data?.full_name||"",
      })  
      setNameEmail({
        name: res.data.full_name,
        email: res.data.email,
      });
    } catch (e) {
      console.log(e.response);
    }
  };

  const updateProfile = async () => {
    setLoading(true);
    try {
      let formData;
      if (pic !== null) {
        formData = new FormData();
        formData.append("phone", profilInfo.phone);
        formData.append("image", pic);
        formData.append("full_name", profilInfo.full_name);
      } else {
        formData = {
          phone: profilInfo.phone,
          full_name: profilInfo.full_name,
        };
      }
      let res = await axios.put(urlUpdate, formData, {
        headers: {
          authorization: `Bearer ${authTokens.access}`,
        },
      });
      console.log(res);
      setBool((prev) => !prev);
      setLoading(false);
      setPic(null);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

 

  useEffect(() => {
    getProfileInfo();
  }, [bool]);

  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#FFF",
    }),
    control: (provided) => ({
      ...provided,
      border: "none",
      outline: "none",
      boxShadow: "none",
      borderRadius: "4px",
      backgroundColor: "#1C1817",
      height: "100%",
      color: "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4299e1" : "#fff",
      color: state.isSelected ? "#fff" : "#4a5568",
      ":hover": {
        backgroundColor: "#EDE0DD",
      },
      outline: "none",
    }),
  };
  const { logoutUser } = useContext(AuthContext);
  return (
    <motion.div
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    transition={{duration:1}}
      className={`w-full ${
        currentMode === "dark" ? "zeroun2" : "zeroun2Light"
      } h-full flex-grow items-stretch flex-shrink flex flex-col overflow-y-auto relative py-2 `}
    >
     
      <div className="w-full flex-grow flex-shrink flex flex-col justify-start items-start relative">
     
        <div className=" flex w-full justify-center items-center " >
        <div
            onClick={() => {}}
            className="ss:w-[70%] w-full relative  h-full hover:cursor-pointer justify-start items-center gap-2 flex flex-row"
          >
            <div className=" bg-white border-[4px] border-[#C00000] rounded-full w-[200px] h-[200px] ">
              <div className="absolute ml-40 mt-32 flex justify-center items-center rounded-full h-12 w-12 bg-primary">
                <AiFillCamera
                  onClick={handleFileUpload}
                  className=" hover:cursor-pointer text-white"
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  className="hidden"
                />
              </div>
              <img
                src={profilInfo.image || avatar}
                alt="avatar"
                className=" rounded-full h-full w-full"
              />
            </div>

            <div className=" w-[50%] ">
              <p className=" dark:text-black text-white text-[14px]">
                {" "}
                {nameEmail.name}{" "}
              </p>
              <p className=" dark:text-black text-white text-[12px] ">
                {nameEmail.email}
              </p>
              <div>
                {pic && (
                  <p className=" text-[10px] text-red-600 ">
                    {" "}
                    click edit to change your image to : {pic.name}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>

        </div>
       
        <div className="flex ss:mt-5 w-full mt-0 flex-col gap-2 justify-center items-center py-10 ">
          <div className="flex flex-col ss:gap-8 justify-center ss:items-start sm:w-[70%] w-[90%] h-full ">
            <div className="h-[70%] justify-center py-2 px-2 items-start md:w-[80%] w-full flex flex-col">
              <div className={` mt-3 ss:w-[70%] w-full gap-1 flex flex-col`}>
                <label
                  className="  dark:text-black text-white font-semibold"
                  htmlFor="full name"
                >
                  Full name
                </label>
                <input
                  value={profilInfo.full_name}
                  onChange={(e) => {
                    setProfileInfo({
                      ...profilInfo,
                      full_name: e.target.value,
                    });
                  }}
                  className=" dark:text-black text-white outline-none rounded-[4px] py-2 px-2 dark:bg-transparent bg-[#1C1817] border-[1px] border-[#A08C89]"
                  type="text"
                />
              </div>

              <div className=" ss:w-[70%] w-full mt-3 flex flex-col gap-1">
                <label
                  className=" dark:text-black text-white font-semibold"
                  htmlFor=""
                >
                  Phone Number
                </label>
                <div className=" rounded-[4px] border-[1px] border-[#A08C89] flex flex-row w-full">
                  <input
                    className="dark:text-black w-full dark:bg-transparent text-white outline-none rounded-r-[4px] py-2 px-2 bg-[#1C1817]"
                    type="text"
                    value={profilInfo.phone}
                    onChange={(e) => {
                      setProfileInfo({ ...profilInfo, phone: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="w-full flex ss:flex-row flex-col ss:gap-0 gap-3 justify-between ss:items-end items-center">
                <div className={` mt-3 ss:w-[70%] w-full gap-1 flex flex-col`}>
                  <label
                    className=" dark:text-black text-white font-semibold"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    value={profilInfo.email}
                    className=" dark:text-black dark:bg-transparent disabled:bg-gray-800 text-white outline-none rounded-[4px] py-2 px-2 bg-[#1C1817] border-[1px] border-[#A08C89]"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>





          </div>
          <div className=" flex ss:justify-start justify-center  items-center mt-10 w-[70%]">
            <button
              onClick={updateProfile}
              className="ss:w-[30%] w-[80%] bg-primary h-10 flex justify-center items-center  font-semibold text-white rounded-[4px]"
            >
              {loading ? <Loader /> : "Edit Profile"}
            </button>
          </div>
        </div>
        <div className=" w-full px-8 flex ss:flex-row flex-row-reverse justify-between items-center">
          <div className=" w-[40%] flex justify-center items-center" >
          <p onClick={logoutUser} className="dark:text-black dark:hover:text-primary text-[24px] gap-2 flex justify-center items-center  hover:cursor-pointer hover:text-primary text-[#FFFBFF]">
          <BiLogOutCircle /> 
              <span className=" text-[18px] underline-offset-1">Logout</span>
              
            </p>

          </div>
          
        
        </div>
      </div>
    </motion.div>
  );
}

export default Profile;
