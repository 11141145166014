import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import TaskCard from "./TaskCard";
import { IoMdAdd } from "react-icons/io";
import axios from "axios";
import AuthContext from "../contexts/AuthContext";
import NewTaskPopup from "./NewTaskPopup";
import TaskDetails from "../components/TaskDetails";
import { BsFillTrashFill } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { useStateContext } from "../contexts/ContextProvider";

const ListColumn = ({
  idProject,
  setShowTask,
  setNewTask,
  setIdList,
  columnId,
  column,
  columns,
  setColumns,
  taskInfo,
  setTaskInfo,
  setClickedId,
}) => {
  const { baseUrl, authTokens } = useContext(AuthContext);
  const [listTitle, setListTitle] = useState(column.title);
  const [editTitle, setEditTitle] = useState(false);
  const [inputTitle, setInputTitle] = useState(column.title);
  const {currentMode}=useStateContext()

  function removeObjectProperty(obj, key) {
    if (obj.hasOwnProperty(key)) {
      
      const updatedObject = { ...obj };
      delete updatedObject[key];
      setColumns(updatedObject);
    } else {
      setColumns(obj);
    }
  }
  const updateListName = async () => {
    if (inputTitle?.length === 0) return;
    try {
      let res = await axios.patch(
        `${baseUrl}teams/boards/${idProject}/lists/${columnId}/`,
        { title: inputTitle },
        {
          headers: {
            authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      setListTitle(inputTitle);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteList = async (id) => {
    try {
      let res = await axios.delete(
        `${baseUrl}teams/boards/${idProject}/lists/${id}/`,
        {
          headers: {
            authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      removeObjectProperty(columns, id);
    } catch (e) {}
  };

  return (
    <Droppable key={columnId} droppableId={columnId}>
      {(provided, snapshot) => (
        <div
          style={{
            background: currentMode==="dark" ? "rgba(255, 255, 255, 0.1)" :"rgba(255, 255, 255, 0.7)" ,

            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          className=" py-4 px-4 relative min-h-[350px] w-[350px] flex flex-col justify-start gap-4 rounded-[6px] "
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <div className=" text-white w-full flex justify-between px-4 items-center ">
            {editTitle ? (
              <input
                value={inputTitle}
                onChange={(e) => {
                  setInputTitle(e.target.value);
                }}
                type="text"
                className=" outline-none bg-transparent dark:text-black text-white font-semibold "
              />
            ) : (
              <p className=" text-white dark:text-black font-semibold ">
                {listTitle}
              </p>
            )}

            <div className=" flex flex-row justify-center items-center gap-2 ">
              {editTitle ? (
                <div className=" flex justify-center items-center gap-1 ">
                  <AiOutlineClose
                    onClick={() => {
                      setEditTitle(false);
                      setInputTitle(listTitle);
                    }}
                    className=" hover:cursor-pointer hover:scale-105 transition duration-300 text-[20px] text-red-500  "
                  />
                  <BsCheckLg
                    className=" hover:cursor-pointer hover:scale-105 transition duration-300 text-[20px] text-green-500  "
                    onClick={() => {
                      setEditTitle((prev) => !prev);
                      updateListName();
                    }}
                  />
                </div>
              ) : (
                <AiOutlineEdit
                  onClick={() => setEditTitle((prev) => !prev)}
                  className=" hover:cursor-pointer hover:scale-105 transition duration-300 text-[20px] text-green-500  "
                />
              )}

              <BsFillTrashFill
                onClick={() => handleDeleteList(columnId)}
                className=" hover:cursor-pointer hover:scale-105 transition duration-300 text-[20px] text-[#C00000]  "
              />
            </div>
          </div>

          {column.items &&
            column?.items?.map((item, index) => (
              <TaskCard
                taskInfo={taskInfo}
                setTaskInfo={setTaskInfo}
                setClickedId={setClickedId}
                setShowTask={setShowTask}
                columns={columns}
                setColumns={setColumns}
                key={item?.id}
                item={item}
                index={index}
              />
            ))}
          <div className=" mt-4 flex w-full justify-center items-center ">
            <button
              onClick={() => {
                setNewTask(true);
                setIdList(columnId);
              }}
              className=" bg-primary flex justify-center items-center rounded-[5px] py-2 px-8 text-white font-semibold "
            >
              <IoMdAdd className=" text-[20px]  " />
              CREATE NEW TASK
            </button>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export const Kanban = ({
  data,
  setAddList,
  columns,
  setColumns,
  idProject,
}) => {
  const { baseUrl, authTokens } = useContext(AuthContext);
  // const [columns, setColumns] = useState(data);
  const [newTask, setNewTask] = useState(false);
  const [idList, setIdList] = useState("");
  const [showTask, setShowTask] = useState(false);
  const [clickedId, setClickedId] = useState("");
  const [taskInfo, setTaskInfo] = useState({});
  const {currentMode}=useStateContext()

  const updateTask = async (taskId, listId) => {
    try {
      let res = await axios.patch(
        `${baseUrl}teams/tasks/${taskId}/update/`,
        { list: listId },
        {
          headers: {
            authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      updateTask(removed.id, destination.droppableId);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
    >
      {showTask && (
        <div className=" fixed left-0 top-0 w-[100%] h-screen bg-black bg-opacity-10 backdrop-blur-sm z-[9999] flex justify-center items-center  ">
          <TaskDetails
            taskInfo={taskInfo}
            setTaskInfo={setTaskInfo}
            clickedId={clickedId}
            setClickedId={setClickedId}
            setShowTask={setShowTask}
          />
        </div>
      )}
      {newTask && (
        <div className=" fixed left-0 top-0 w-[100%] h-screen bg-black bg-opacity-60 backdrop-blur-sm z-[9999] flex justify-center items-center  ">
          <NewTaskPopup
            columns={columns}
            setColumns={setColumns}
            idList={idList}
            setNewTask={setNewTask}
          />
        </div>
      )}
      <div className=" w-full ">
        <div className=" w-full flex gap-7 flex-wrap  ">
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <ListColumn
               key={columnId}
                idProject={idProject}
                setNewTask={setNewTask}
                setIdList={setIdList}
                setShowTask={setShowTask}
                setClickedId={setClickedId}
                setTaskInfo={setTaskInfo}
                columns={columns}
                setColumns={setColumns}
                taskInfo={taskInfo}
                columnId={columnId}
                column={column}
              />
            );
          })}
          <div
            style={{
              background: currentMode==="dark" ? "rgba(255, 255, 255, 0.1)" :"rgba(255, 255, 255, 0.7)" ,

              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            className="h-[100px] min-w-[300px] px-4 py-4 rounded-[6px] "
          >
            <div
              onClick={() => setAddList(true)}
              className=" hover:cursor-pointer hover:bg-opacity-50 text-white dark:text-black w-full flex justify-between px-4 items-center "
            >
              <IoMdAdd className=" text-[20px]  " />
              <p className="  font-semibold ">Add Another list</p>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};
