import React, { useState, useEffect, useRef, useContext } from "react";
import { GiCancel } from "react-icons/gi";
import taskLogo from "../assets/TaskLogo.svg";
import axios from "axios";
import AuthContext from "../contexts/AuthContext";
import Select from "react-select";
import Loader from "./Loader";

function NewTaskPopup({ infos, setNewTask, idList, columns, setColumns }) {
  const { baseUrl, authTokens } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#1C1817",
    }),
    control: (provided) => ({
      ...provided,
      border: "1px solid gray",
      outline: "none",
      boxShadow: "none",
      borderRadius: "5px",
      backgroundColor: "transparent",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4299e1" : "#fff",
      color: state.isSelected ? "#fff" : "#4a5568",
      ":hover": {
        backgroundColor: "#EDE0DD",
      },
    }),
  };
  const popupRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closePopup = () => {
    setNewTask(false);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const handleMemberChange = (selectedOption) => {
    setSelectedMember(selectedOption);
  };

  const getMembers = async () => {
    try {
      let res = await axios.get(`${baseUrl}teams/members/`, {
        headers: {
          authorization: `Bearer ${authTokens.access}`,
        },
      });

      console.log(res);
      let tmp = res.data.results.map((item) => {
        return {
          label: item.username,
          value: item.id,
        };
      });
      setMembers(tmp);
    } catch (e) {}
  };

  useEffect(() => {
    getMembers();
  }, []);

  const createTask = async () => {
    setLoading(true);
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    try {
      let res = await axios.post(
        `${baseUrl}teams/tasks/create/`,
        {
          title: title,
          description: description,
          due_date: formattedDate,
          priority: selectedOption.value,
          assigned_to: selectedMember.value,
          list: idList,
        },
        {
          headers: {
            authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      console.log(res)
      const destColumn = columns[idList];
      let tmp = columns[idList].items;
      setColumns({
        ...columns,
        [idList]: {
          ...destColumn,
          items: [...tmp, res.data],
        },
      });
      setLoading(false);
      closePopup();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div
      ref={popupRef}
      className=" animate-slideup dark:bg-[#FDF6F4] bg-[#1C1817]  sm:w-[50%] w-[90%] flex justify-center items-start flex-col rounded-[12px] z-[10] absolute py-4 "
    >
      <div
        onClick={() => {
          setNewTask(false);
        }}
        className=" w-full flex justify-end items-center px-4"
      >
        {" "}
        <button className=" cursor-pointer text-[25px] dark:text-black dark:hover:drop-shadow-xl dark:hover:bg-slate-50 dark:hover:bg-opacity-50 text-white hover:bg-light-gray hover:bg-opacity-25  rounded-full p-2 ">
          {" "}
          <GiCancel />{" "}
        </button>{" "}
      </div>
      <div className="w-full flex flex-col justify-center items-center gap-8 dark:text-black text-white ">
        <img src={taskLogo} alt="" />
        <p className=" text-white dark:text-black text-[30px] ">ADD NEW TASK</p>
        <div className=" ss:w-[70%] w-full flex flex-col justify-center items-start ">
          <label htmlFor="" className=" text-gray-300  ">
            Task title
          </label>
          <input
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            placeholder="Enter the task title"
            className=" w-full bg-transparent py-2 px-4 border-[1px] border-[#b1b1b1] rounded-[5px] "
            type="text"
          />
        </div>
        <div className=" ss:w-[70%] w-full flex flex-col justify-center items-start ">
          <label htmlFor="" className=" text-gray-300  ">
            Importance
          </label>
          <Select
            className="w-full"
            placeholder="Select"
            styles={customStyles}
            options={[
              { value: "High", label: "High" },
              { value: "Medium", label: "Medium" },
              { value: "Low", label: "Low" },
            ]}
            value={selectedOption}
            onChange={handleChange}
          />
        </div>
        <div className=" ss:w-[70%] w-full flex flex-col justify-center items-start ">
          <label htmlFor="" className=" text-gray-300  ">
            Member
          </label>
          <Select
            className="w-full"
            placeholder="Select"
            styles={customStyles}
            options={members}
            value={selectedMember}
            onChange={handleMemberChange}
          />
        </div>
        <div className=" ss:w-[70%] w-full flex flex-col justify-center items-start ">
          <label htmlFor="" className=" text-gray-300  ">
            Description
          </label>
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            style={{ resize: "none" }}
            rows={5}
            placeholder="Enter the task title"
            className=" w-full bg-transparent py-2 px-4 border-[1px] border-[#b1b1b1] rounded-[5px] "
            type="text"
          />
        </div>
        <button
          onClick={() => createTask()}
          className=" bg-primary rounded-[5px] h-10 w-44 flex justify-center items-center text-white font-semibold "
        >
          {loading ? <Loader /> : "ADD TASK"}
        </button>
      </div>
    </div>
  );
}

export default NewTaskPopup;
