import React, { useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { FiMoreHorizontal } from "react-icons/fi";

import { MdOutlineArrowForwardIos } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import axios from "axios";
import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { useEffect } from "react";
import Loader from "./Loader";
import tmp from "../assets/imgSvg.svg";
import { CgDetailsMore } from "react-icons/cg";
import { useStateContext } from "../contexts/ContextProvider";

const TaskCard = ({
  item,
  index,
  columns,
  setColumns,
  setShowTask,
  clickedId,
  setClickedId,
  taskInfo,
  setTaskInfo,
}) => {
  const { baseUrl, authTokens } = useContext(AuthContext);
  const {currentMode}=useStateContext()
  const [toggle, setToggle] = useState(false);
  const [priority, setPriority] = useState(item?.priority);
  const [lists, setLists] = useState([]);
  const menuRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(0);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closePopup();
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closePopup = () => {
    setToggle(false);
    setHovered(0);
  };

  const { title, id } = useParams();

  const deleteTask = async () => {
    setLoading(true);
    try {
      let res = await axios.delete(`${baseUrl}teams/tasks/${item.id}/`, {
        headers: {
          authorization: `Bearer ${authTokens.access}`,
        },
      });
      const destColumn = columns[item.list];
      let tmp = columns[item.list].items.filter((it) => it.id !== item.id);
      setColumns({
        ...columns,
        [item.list]: {
          ...destColumn,
          items: tmp,
        },
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const updateTaskPriority = async (priority) => {
    try {
      let res = await axios.patch(
        `${baseUrl}teams/tasks/${item.id}/update/`,
        { priority: priority },
        {
          headers: {
            authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      setPriority(priority);
    } catch (e) {
      console.log(e);
    }
  };

  const updateTask = async (listId) => {
    try {
      let res = await axios.patch(
        `${baseUrl}teams/tasks/${item.id}/update/`,
        { list: listId },
        {
          headers: {
            authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      const sourceColumn = columns[item.list];
      const destColumn = columns[listId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(index, 1);
      let tmp = { ...removed, list: listId };
      destItems.splice(0, 0, tmp);
      setColumns({
        ...columns,
        [item.list]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [listId]: {
          ...destColumn,
          items: destItems,
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getLists = async () => {
    try {
      let res = await axios.get(`${baseUrl}teams/boards/${id}/lists/`, {
        headers: {
          authorization: `Bearer ${authTokens.access}`,
        },
      });
      let tmp = res?.data?.results.filter((it) => it.id !== item.list);
      setLists(tmp);
    } catch (e) {}
  };
  useEffect(() => {
    getLists();
  }, []);
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="dark:bg-[#FDF6F4] min-h-[150px] relative bg-[#1C1817]  rounded-[6px] px-4 py-8 w-full flex flex-col gap-3 justify-center items-start ">
            <div
              className={` absolute w-[4px]  rounded-l-[6px] -left-[4px] h-full ${
                priority === "Low"
                  ? "bg-green-500"
                  : priority === "Medium"
                  ? "bg-orange-500"
                  : "bg-red-500"
              } `}
            />
            {toggle && (
              <div
                ref={menuRef}
                style={{
                  background: currentMode==="dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(255, 255, 255, 0.7)" ,

                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
                className={`px-4 py-6 text-white dark:text-black absolute z-[99999] top-12 -right-44 mx-4 my-2 w-[200px] rounded-[5px]`}
              >
                <ul className="w-full gap-4 list-none flex flex-col justify-end items-center">
                  <li
                    onMouseEnter={() => setHovered(1)}
                    className=" hover:text-white hover:bg-[#1C1817] hover:cursor-pointer py-2 px-3 w-full flex flex-row justify-between items-center "
                  >
                    <p>change level</p> <MdOutlineArrowForwardIos />
                    {hovered === 1 && (
                      <div
                        onMouseEnter={() => setHovered(1)}
                        style={{
                          background: currentMode==="dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(255, 255, 255, 0.7)" ,
                          backdropFilter: "blur(10px)",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                        className={`px-1 py-2 text-white dark:text-black flex flex-col gap-1 absolute -right-[266px] z-[99999] top-12 mx-4 my-2 w-[250px]`}
                      >
                        {/* <div className=" rounded-[5px] hover:cursor-pointer bg-blue-500 w-full py-2 flex justify-center items-center ">
                          Delegate if possible
                        </div> */}
                        <div
                          onClick={() => updateTaskPriority("Low")}
                          className=" text-white font-semibold rounded-[5px] hover:cursor-pointer bg-green-500 w-full py-2 flex justify-center items-center "
                        >
                          Lower priority
                        </div>
                        <div
                          onClick={() => updateTaskPriority("Medium")}
                          className=" text-white font-semibold rounded-[5px] hover:cursor-pointer bg-orange-500 w-full py-2 flex justify-center items-center "
                        >
                          Medium priority
                        </div>
                        <div
                          onClick={() => updateTaskPriority("High")}
                          className=" text-white font-semibold rounded-[5px] hover:cursor-pointer bg-red-500 w-full py-2 flex justify-center items-center "
                        >
                          High priority
                        </div>
                      </div>
                    )}
                  </li>
                  <li
                    onMouseEnter={() => setHovered(2)}
                    className=" w-full hover:bg-[#1C1817] hover:text-white hover:cursor-pointer py-2 px-3 flex flex-row justify-between items-center "
                  >
                    <p>move to</p> <MdOutlineArrowForwardIos />
                    {hovered === 2 && (
                      <div
                        onMouseEnter={() => setHovered(2)}
                        style={{
                          background: currentMode==="dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(255, 255, 255, 0.7)" ,
                          backdropFilter: "blur(10px)",

                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                        className={`px-2 py-1 dark:text-black text-white absolute z-[99999] top-12 -right-[266px] mx-4 my-2 w-[250px]`}
                      >
                        {lists.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                updateTask(item.id);
                              }}
                              className=" py-2 px-2 hover:cursor-pointer hover:text-white hover:bg-[#1C1817] w-full flex justify-between items-center "
                            >
                              {item.title}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </li>
                  <li
                    onMouseEnter={() => setHovered(0)}
                    onClick={() => deleteTask()}
                    className=" w-full hover:text-white hover:bg-[#1C1817] hover:cursor-pointer h-10 px-3 flex flex-row justify-between items-center "
                  >
                    <p>delete task</p> {loading ? <Loader /> : <BsTrash />}
                  </li>
                </ul>
              </div>
            )}
            <div className="w-full flex flex-row justify-between items-center">
              <div className="flex flex-row gap-2 items-center justify-center">
                <div
                  className={` ${
                    priority === "Low"
                      ? "bg-green-500"
                      : priority === "Medium"
                      ? "bg-orange-500"
                      : "bg-red-500"
                  }  w-2 h-2  rounded-full`}
                />
                <p className="text-gray-400 font-semibold text-[12px]">
                  <span
                    className={` ${
                      priority === "Low"
                        ? "bg-green-500"
                        : priority === "Medium"
                        ? "bg-orange-500"
                        : "bg-red-500"
                    }  text-white px-1 rounded-md mr-2`}
                  >
                    {priority}
                  </span>
                  {title}
                </p>
              </div>
              <div className=" flex justify-center items-center gap-3 ">
                <FiMoreHorizontal
                  onClick={() => setToggle((prev) => !prev)}
                  className="hover:cursor-pointer dark:text-black text-white"
                />
                <CgDetailsMore
                  onClick={() => {
                    setShowTask(true);
                    setClickedId(item.id);
                    setTaskInfo(item);
                  }}
                  className="hover:cursor-pointer dark:text-black text-white"
                />
              </div>
            </div>

            <h1 className=" text-[20px] text-white dark:text-black font-semibold ">
              {item.title}
            </h1>
            <p className=" text-[12px] text-white dark:text-black ">
              {item.description}
            </p>
            {/* <div className=" w-full relative   ">
              <input
                type="text"
                className=" w-full outline-none z-0 border-[1px] bg-transparent rounded-md border-[#fff] border-opacity-70 py-2 px-4 "
                placeholder="Write comment here"
              />
              <img
                src={tmp}
                alt=""
                className=" top-3 right-6 absolute z-10  "
              />
            </div> */}
            {item?.assigned_to?.image ? (
              <img
                src={item?.assigned_to?.image}
                alt=""
                className=" h-6 w-6 object-contain "
              />
            ) : (
              <div className=" text-[8px] text-white flex justify-center items-center h-6 w-6 rounded-full bg-green-600 ">
                AG
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
